var site = site || {};
site.inits = [];

site.accessibility = {
	init:function() {
		$(document).keydown(function (e) {
			if (e.which === 9) {
				$('body:not(.admin)').addClass('tabbing');
			}
		});
		$(document).mousedown(function (e) {
			$('body.tabbing').removeClass('tabbing');
		});
	}
};

// Run all the inits \o/
$(function() {
	$.each(site.inits, function(i, value) {
		var fun = new Function(value);
		fun();
	});

	site.accessibility.init();

	var $body = $('body');

	//Responsive youtube and vimeo iframe
	$('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]', '#main').each(function() {
		$(this).attr('allowfullscreen','').wrap('<div class="video" style="max-width:'+$(this).width()+'px"><div class="embed-responsive embed-responsive-16by9"></div></div>');
	});

	// Responsive tables
	if (!$body.is('.admin')) {
		$('table', '#main').wrap('<div class="table-responsive"></div>');
	}

});
