/**
 * Autogenerated file don't modify
 */
(function () {
(function () {
    'use strict';

    var socialLinksExist = function (el) {
        var socialElms = el.querySelectorAll('div[data-social-links]');
        if (!socialElms) {
            return;
        }
        MoyaLoader.js('/static/local/ext-social/js/social.min.js').then(function () {
            var socialLinks = MoyaContainer.get('ext.social.links');
            socialElms.forEach(function (elm) {
                socialLinks.init(elm);
            });
        });
    };
    socialLinksExist(document);
    MoyaEventHandler.on('modal.opened', function (e) {
        socialLinksExist(e.modal.element);
    });

}());
typeof tsBuild === 'function' && tsBuild();})();
