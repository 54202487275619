/**
 * Autogenerated file don't modify
 */
(function () {
var tsBuild = (function (exports) {
    'use strict';

    var util = MoyaContainer.get('core.util');
    MoyaComponents.register('ext.mobilemenu', function (el) {
        var mobileMenuConfig = {
            mainMenuElement: el,
            mainMenuToggleElement: document.getElementById('mainmenuToggle'),
            mobileMenuElement: el,
        };
        MobileMenu.create(mobileMenuConfig);
    });
    var MobileMenu = /** @class */ (function () {
        function MobileMenu(config) {
            this.mainMenuElement = config.mainMenuElement;
            this.mainMenuToggleElement = config.mainMenuToggleElement;
            this.mobileMenuElement = config.mainMenuElement;
        }
        MobileMenu.create = function (config) {
            return new MobileMenu(config).run();
        };
        MobileMenu.prototype.run = function () {
            if (!this.mainMenuElement) {
                return;
            }
            this.checkAttributes();
            this.addExpand();
            this.bind();
            this.dispatchEvent();
        };
        MobileMenu.prototype.bind = function () {
            var _this = this;
            util.delegate(this.mobileMenuElement, 'click', 'body', function (e) {
                e.stopPropagation();
            });
            util.delegate(document.body, 'click', 'body', function (e) {
                _this.bodyClick(e);
            });
            util.delegate(this.mainMenuToggleElement, 'click', '#mainmenuToggle', function (e) {
                _this.onMenuClick(e);
            });
            Array.prototype.forEach.call(this.mainMenuElement.querySelectorAll('li[class*="selectedLevel"]'), function (el) {
                el.classList.add('down');
            });
        };
        MobileMenu.prototype.onMenuClick = function (e) {
            e.stopPropagation();
            e.preventDefault();
            var menuOpen = document.body.classList.contains('mainmenu-open');
            this.mobileToggle(menuOpen);
            if (!menuOpen) {
                this.mainMenuToggleElement.setAttribute('aria-expanded', 'true');
            }
            else {
                this.mainMenuToggleElement.removeAttribute('aria-expanded');
            }
        };
        MobileMenu.prototype.addExpand = function () {
            Array.prototype.forEach.call(this.mobileMenuElement.querySelectorAll('.children'), function (el) {
                el.prepend(MobileMenu.createExpandElement());
            });
            Array.prototype.forEach.call(this.mobileMenuElement.querySelectorAll('.expand'), function (el) {
                el.addEventListener('click', function (e) {
                    MobileMenu.expandClick(e, el);
                });
            });
        };
        MobileMenu.createExpandElement = function () {
            var expand = document.createElement('button');
            expand.classList.add('expand');
            expand.setAttribute('aria-label', 'Opna/loka');
            return expand;
        };
        MobileMenu.prototype.mobileToggle = function (menuOpen) {
            document.body.classList.toggle('mainmenu-open');
            if (menuOpen) {
                this.closeMenu(menuOpen);
            }
        };
        MobileMenu.prototype.closeMenu = function (menuOpen) {
            if (menuOpen) {
                document.body.classList.add('mainmenu-closing');
                setTimeout(function () {
                    document.body.classList.remove('mainmenu-closing');
                }, 300);
            }
        };
        MobileMenu.prototype.bodyClick = function (e) {
            if (document.body.classList.contains('mainmenu-open')) {
                document.body.classList.remove('mainmenu-open');
                this.closeMenu(true);
            }
        };
        MobileMenu.prototype.searchbox = function () {
            var mSearchBoxClone = document.getElementById('searchBox').cloneNode(true);
            mSearchBoxClone.id = 'mSearchBox';
            this.mobileMenuElement.prepend(mSearchBoxClone);
            var mSearchBox = document.getElementById('mSearchBox');
            var children = mSearchBox.querySelectorAll('*');
            for (var i = 0; i < children.length; i++) {
                var item = children[i], id = item.getAttribute('id');
                if (id) {
                    var newId = 'm' + id.substr(0, 1).toUpperCase() + id.substr(1), label = mSearchBox.querySelector('label[for="' + id + '"]');
                    if (!!label) {
                        label.setAttribute('for', newId);
                    }
                    item.setAttribute('id', newId);
                }
            }
        };
        MobileMenu.prototype.languages = function () {
            var divisions = document.getElementById('divisions');
            if (!divisions) {
                return;
            }
            var menu = this.mainMenuElement.querySelector('ul.mmLevel1');
            var html = MobileMenu.createLangLi();
            divisions.querySelectorAll('a').forEach(function (child) {
                if (child.closest('li').classList.contains('selected')) {
                    return;
                }
                var divisionLink = child.cloneNode(true);
                html.append(divisionLink);
            });
            menu.append(html);
        };
        MobileMenu.createLangLi = function () {
            var li = document.createElement('li');
            li.classList.add('ele-language');
            li.classList.add('si');
            li.classList.add('level1');
            return li;
        };
        MobileMenu.prototype.qn = function () {
            var qn = document.getElementById('qn');
            var menu = this.mobileMenuElement.querySelector('ul.mmLevel1');
            var qnContent = '';
            var selector = this.mainMenuElement.hasAttribute('data-qn-selector') ? this.mainMenuElement.getAttribute('data-qn-selector') : 'li';
            qn.querySelectorAll(selector).forEach(function (item) {
                var qnItem = item.cloneNode(true);
                qnItem.classList.add('mobileQn');
                menu.append(qnItem);
            });
            menu.append(qnContent);
        };
        MobileMenu.prototype.prependHeader = function () {
            this.mobileMenuElement.querySelectorAll('.mmLevel1 li ul').forEach(function (item) {
                var title = item.parentNode.querySelector('a').cloneNode(true);
                title.classList.add('back');
                var wrap = document.createElement('div');
                wrap.classList.add('header');
                wrap.prepend(title);
                util.delegate(title, 'click', 'body', function (e) {
                    e.preventDefault();
                    item.parentElement.classList.remove('down');
                });
                item.prepend(wrap);
            });
        };
        MobileMenu.prototype.prependHome = function () {
            var _this = this;
            this.mobileMenuElement.querySelectorAll('.mmLevel1 li ul').forEach(function (item) {
                var home = document.createElement('a');
                home.classList.add('home');
                home.setAttribute('href', '#');
                var text = document.createElement('span');
                text.classList.add('sr-only');
                text.innerHTML = 'Heim';
                home.prepend(text);
                var wrap = document.createElement('div');
                wrap.classList.add('navbar');
                wrap.prepend(home);
                util.delegate(home, 'click', 'body', function (e) {
                    e.preventDefault();
                    _this.mobileMenuElement.querySelectorAll('.mmLevel1 li').forEach(function (item) {
                        item.classList.remove('down');
                    });
                });
                item.prepend(wrap);
            });
        };
        MobileMenu.prototype.cloneMenu = function () {
            var html = document.createElement('nav');
            html.setAttribute('id', 'mobilemenu');
            html.classList.add('hidden-md');
            html.classList.add('hidden-lg');
            var menu = this.mainMenuElement.querySelector('ul.mmLevel1').cloneNode(true);
            menu.removeAttribute('id');
            menu.querySelector('ul').removeAttribute('id');
            html.append(menu);
            this.mainMenuElement.parentNode.insertBefore(html, this.mainMenuElement.nextSibling);
            this.mobileMenuElement = html;
            this.mainMenuElement.classList.add('hidden-sm');
            this.mainMenuElement.classList.add('hidden-xs');
            Array.prototype.forEach.call(this.mobileMenuElement.querySelectorAll('li[class*="selectedLevel"]'), function (el) {
                el.classList.add('down');
            });
        };
        MobileMenu.expandClick = function (e, el) {
            e.preventDefault();
            e.stopPropagation();
            el.closest('li').classList.toggle('down');
        };
        MobileMenu.prototype.dispatchEvent = function () {
            MoyaEventHandler.dispatch('ext.mobilemenu.init', {
                'mobilemenu': this
            });
        };
        MobileMenu.prototype.checkAttributes = function () {
            if (this.mainMenuElement.hasAttribute('data-clone')) {
                this.cloneMenu();
            }
            if (this.mainMenuElement.hasAttribute('data-searchbox')) {
                this.searchbox();
            }
            if (this.mainMenuElement.hasAttribute('data-qn')) {
                this.qn();
            }
            if (this.mainMenuElement.hasAttribute('data-languages')) {
                this.languages();
            }
            if (this.mainMenuElement.hasAttribute('data-mMenu')) {
                this.prependHeader();
                if (this.mainMenuElement.hasAttribute('data-homeButton')) {
                    this.prependHome();
                }
            }
        };
        return MobileMenu;
    }());

    exports.MobileMenu = MobileMenu;

    return exports;

}({}));
typeof tsBuild === 'function' && tsBuild();})();
